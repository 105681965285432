export default class Glossary {
    public sourceLanguage: string = 'EN-US';
    public targetLanguage: string;
    public from: string;
    public to: string;

    constructor(obj?: Partial<Glossary>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class GlossaryInfo {
    public name: string;
    public source_lang: string;
    public target_lang: string;
}