import { Menu } from '@/models/MetaInformation';

export default [
    {
        name: 'Translations',
        path: '/translations',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'prepaid-overview' },
        children: [
            {
                name: 'translation-status',
                path: '/translations/status',
                component: require('@/modules/translations/views/status.vue').default,
                meta: {
                    menu: new Menu({
                        showInMenu: true,
                        icon: 'check-circle',
                        label: 'Status',
                        roles: ['SuperAdmin', 'Practitioner', 'Partner'],
                        permissions: ['Translator'],
                    }),
                },
            },
            {
                name: 'translations',
                path: '/translations/translations/:knowledgeModelAlias?/:instrumentAlias?/:languageCode?/:section?/:subSection?/:reportAlias?',
                component: require('@/modules/translations/views/translations.vue').default,
                meta: {
                    menu: new Menu({
                        showInMenu: true,
                        icon: 'pencil',
                        label: 'Translations',
                        roles: ['SuperAdmin', 'Practitioner', 'Partner'],
                        permissions: ['Translator'],
                    }),
                },
            },
            {
                name: 'glossaries',
                path: '/translations/glossaries',
                component: require('@/modules/translations/views/glossaries.vue').default,
                meta: {
                    menu: new Menu({
                        showInMenu: true,
                        icon: 'book',
                        label: 'Glossaries',
                        roles: ['SuperAdmin', 'Practitioner', 'Partner'],
                        permissions: ['Translator'],
                    }),
                },
            },
            {
                name: 'translators',
                path: '/translations/translators',
                component: require('@/modules/translations/views/translators.vue').default,
                meta: {
                    menu: new Menu({
                        showInMenu: true,
                        icon: 'user',
                        label: 'Translators',
                        roles: ['SuperAdmin'],
                    }),
                },
            },
        ],
        meta: {
            menu: new Menu({
                showInMenu: true,
                icon: 'pencil',
                label: 'Invoices',
                variant: 'success-clean',
                roles: ['SuperAdmin', 'Practitioner', 'Partner'],
                permissions: ['Translator'],
            }),
        },
    },
];
