import { Component, Prop } from 'vue-property-decorator';
import BaseParticipantManagement from '@/components/participant-management/baseParticipantManagement';
import to from 'await-to-js';
import Participant, { DemographicData } from '@/modules/participants/models/Participant';
import { ParticipantsService } from '@/modules/participants/services/participantsService';
import { OrganizationsService } from '@/services/organizationsService';
import ExcelService from '@/services/ExcelService';

enum ImportInputModes {
    Overview = 'overview',
    Import = 'import',
    Paste = 'paste',
}

@Component
export default class OrganizationAddDemographicDataComponent extends BaseParticipantManagement {
    @Prop({ default: () => [] }) public participants: Participant[];
    @Prop({ default: () => null }) public projectId: number;

    public inputModel: string = '';
    public inputMode: ImportInputModes = ImportInputModes.Paste;
    public lineData: ParticipantImportBase[] = [];
    public replaceCurrentDemographicData: boolean = false;
    private readonly delimiter: string = ';';
    private csvColumns: CsvColumn[] = [];
    private participantsService: ParticipantsService = new ParticipantsService();
    private organizationsService: OrganizationsService = new OrganizationsService();

    public async created() {
        this.csvColumns = new Array<CsvColumn>(
            { label: 'emailAddress', placeholder: 'example@address.com' },
            { label: 'label 1', placeholder: 'value 1' },
            { label: 'label 2', placeholder: 'value 2' },
        );
    }

    public cancel(): void {
        this.$emit('cancel');
    }

    public async importLines(): Promise<void> {
        this.showPending('Loading...');
        const demographicData = [];

        this.lineData.forEach((x: Partial<ParticipantImportBase>) => {
            demographicData.push({
                emailAddress: x.emailAddress,
                demographicData: x.demographicData,
            });
        });

        if (this.projectId) {
            const [err] = await to(
                this.participantsService.updateMultipleDemographicDataOnProject(demographicData, this.projectId, this.replaceCurrentDemographicData),
            );
            if (err) {
                return this.clearAndShowError('Failed to add demographic data', err);
            }
            this.clearAndShowSuccess('Demographic data added to participants of project');
        } else {
            const [err] = await to(
                this.participantsService.updateMultipleDemographicData(demographicData, this.organizationId, this.replaceCurrentDemographicData),
            );
            if (err) {
                return this.clearAndShowError('Failed to add demographic data', err);
            }
            this.clearAndShowSuccess('Demographic data added to participants');
        }

        this.clearNotifications();
        this.$emit('finished');
    }

    public fileUploaded(event) {
        // tslint:disable: no-use-before-declare
        this.loadCSV(event, new ParticipantImportBase());
    }

    public async getDemographicModel() {
        const [err, response] = await to(this.organizationsService.getDemographicModel(this.organizationId));
        if (err) {
            return this.showError('Failed to load demographic model');
        }
        return response.data;
    }

    public async downloadExample() {
        const demographicModel = await this.getDemographicModel();

        if (demographicModel) {
            const csvColumns: CsvColumn[] = [{ label: 'emailAddress', placeholder: 'example@address.com' }];

            demographicModel.questions.forEach((x) => {
                csvColumns.push({ label: x.label, placeholder: x.answers.join('|') });
            });

            this.exportExampleCSV(csvColumns);
            return;
        }

        this.exportExampleCSV(this.csvColumns);
    }

    public async exportExampleCSV(columns: CsvColumn[]): Promise<void> {
        const downloadLink = await new ExcelService().fetchExampleImport(columns);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    public loadCSV<T>(e: any, object: T): void {
        const self = this;
        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = (event.target as any).result;
                self.processInput(csv, self.delimiter);
            };

            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert('Cannot read file !');
                }
            };

            reader.onloadend = () => {
                return object;
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }

    public processPastedInput(): void {
        this.processInput(this.inputModel, '\t');
    }

    public processInput(input: string, delimiter: string) {
        const self = this;
        const lines = input.split('\n');
        const mapping = []; // Once to be used by a mailchimp style importer?

        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        for (let i = 0; i < headers.length; i++) {
            mapping.push({ index: i, property: headers[i] });
        }

        for (let li = 1; li < lines.length; li++) {
            // Skip first line
            if (lines[li] === null || lines[li] === '') {
                continue;
            }

            const columns = lines[li].replace('\r', '').split(delimiter);
            const itemLine = new ParticipantImportBase();

            for (let m = 0; m < mapping.length; m++) {
                const currentMap = mapping[m];

                if (m === 0) {
                    if (currentMap.property.toLowerCase() === 'emailaddress') {
                        itemLine.emailAddress = columns[currentMap.index];
                    } else {
                        itemLine[currentMap.property] = columns[currentMap.index];
                    }
                } else {
                    if (columns[currentMap.index]) {
                        itemLine.demographicData.push(new DemographicData({ value: columns[currentMap.index], label: currentMap.property }));
                    }
                }
            }

            self.lineData.push(itemLine);
        }
    }

    public cancelImport(): void {
        this.cancel();
    }
}

// tslint:disable: max-classes-per-file
class ParticipantImportBase {
    public demographicData: DemographicData[] = [];
    public emailAddress: string = '';
}

export class CsvColumn {
    public label: string = '';
    public placeholder: string = '';
}
