import Component from 'vue-class-component';
import to from 'await-to-js';
import { Translation } from '@/models/Translations';
import BasePage from '@/models/BasePage';
import { PartnerService } from '@/modules/partners/services/partnerService';
import { PractitionerService } from '@/modules/practitioners/services/practitionerService';
import LanguagePermission, { InstrumentPermission } from '@/modules/settings/models/LanguagePermission';
import { LanguagePermissionService } from '@/modules/settings/services/languagePermissionsService';
import { languageService } from '@/main';
import { KnowledgeModelService } from '@/modules/knowledge-models/services/knowledgeModelService';
import KnowledgeModel from '@/modules/knowledge-models/models/KnowledgeModel';
import { PagedResponse } from '@/models/PagedResponse';
import { Language } from '@/models/Language';
import Instrument from '@/modules/instruments/Models/Instrument';
import { CreateKnowledgeModelTranslationSideActionOptions } from '@/modules/knowledge-models/side-actions/add-translation-action';
import PendingTranslation from '@/models/PendingTranslation';

@Component({})
export default class Translations extends BasePage {
    public isLoading: boolean = true;
    public genericInstrument: Instrument = new Instrument({ instrumentId: -1, alias: 'generic', name: 'Generic' });
    public selectedInstrument: Instrument = this.genericInstrument;
    public selectedKnowledgeModel: KnowledgeModel = null;
    public selectedLanguage: Language = new Language({ languageCode: 'en', languageName: 'English' });
    public selectedSection: any = null;
    public selectedSubSection: any = null;
    public selectedReportAlias: any = null;
    public showDuplicates: boolean = false;
    public missingTranslationsKey: string = new Date().getTime().toString();
    public pendingTranslatedTranslations: PendingTranslation[] = [];

    public instruments: Instrument[] = [this.genericInstrument];
    public languagePermissions: LanguagePermission[] = [];
    public knowledgeModels: KnowledgeModel[] = [];
    public languages: Language[] = [];
    public allLanguages: Language[] = [];
    public reportAliases: any[] = [];
    public showWarnings: boolean = false;

    public translations: Translation[] = [];
    public allTranslations: Translation[] = [];
    public englishTranslations: Translation[] = [];
    public allEnglishTranslations: Translation[] = [];

    public sections: any[] = [
        { id: 0, name: 'Generic', alias: 'generic', subSections: [{ name: 'Generic', alias: 'generic', id: 0 }] },
        {
            id: 1,
            name: 'Survey',
            alias: 'survey',
            subSections: [
                { name: 'Questions', alias: 'surveyQuestions', id: 1 },
                { name: 'Answers', alias: 'surveyAnswers', id: 2 },
            ],
        },
        {
            id: 2,
            name: 'Reports',
            alias: 'reports',
            subSections: [
                { name: 'Reports (web)', alias: 'reportsWeb', id: 3 },
                { name: 'Reports (other)', alias: 'reportsPowerpoint', id: 4 },
            ],
        },
    ];

    public reloaded: number = 0;
    public translated: number = 0;

    public practitionersService: PractitionerService = new PractitionerService();
    public partnersService: PartnerService = new PartnerService();
    public languagePermissionService: LanguagePermissionService = new LanguagePermissionService();
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public get duplicateTranslations() {
        const translations = [];

        this.translations.forEach((translation) => {
            if (
                this.translations.filter(
                    (x) =>
                        x.key === translation.key &&
                        x.value === translation.value &&
                        x.section === translation.section &&
                        x.subSection === translation.subSection &&
                        x.reportAlias === translation.reportAlias,
                ).length > 1
            ) {
                translations.push(translation);
            }
        });

        return translations;
    }

    public get missingTranslations() {
        return this.englishTranslations
            .filter(
                (x) =>
                    !this.translations.find(
                        (y) => y.key === x.key && y.section === x.section && y.subSection === x.subSection && y.reportAlias === x.reportAlias,
                    ),
            )
            .map((x) => {
                return {
                    key: x.key,
                    section: x.section,
                    subSection: x.subSection,
                    reportAlias: x.reportAlias,
                };
            });
    }

    public async created() {
        await this.getLanguagePermissions();
        await this.getInstruments();
        await this.getKnowledgeModels();

        if (this.isSuperAdmin) {
            this.allLanguages = languageService.getLanguages();
        } else {
            this.allLanguages = this.languagePermissions[0].instrumentPermissions
                .filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId)
                .map((x) => new Language(x));
        }

        if (this.knowledgeModels && this.knowledgeModels.length) {
            if (this.$route.params.knowledgeModelAlias) {
                this.selectedKnowledgeModel = this.knowledgeModels.find((x) => x.alias === this.$route.params.knowledgeModelAlias);
            } else {
                this.selectedKnowledgeModel = this.knowledgeModels[0];
            }

            await this.reloadTranslationsKnowledgeModel(this.selectedKnowledgeModel, false);
        } else {
            this.showError(`You don't have rights to any knowledge model`);
        }

        if (this.instruments && this.instruments.length) {
            if (this.$route.params.knowledgeModelAlias) {
                this.selectedInstrument = this.instruments.find((x) => x.alias === this.$route.params.instrumentAlias);
            } else {
                this.selectedInstrument = this.genericInstrument;
            }
        } else {
            this.showError(`You don't have rights to any instrument`);
        }

        if (this.languages && this.languages.length) {
            if (this.$route.params.knowledgeModelAlias) {
                this.selectedLanguage = this.allLanguages.find((x) => x.languageCode === this.$route.params.languageCode);
            } else {
                this.selectedLanguage = this.allLanguages.find((x) => x.languageCode == 'en')
                    ? this.allLanguages.find((x) => x.languageCode == 'en')
                    : this.allLanguages[0];
            }
        } else {
            this.showError(`You don't have rights to any language`);
        }

        this.allTranslations = this.translations = await this.getTranslations();
        await this.getEnglishTranslations();

        if (this.$route.params.section) {
            const section = this.sections.find((x) => x.alias === this.$route.params.section);
            this.sectionSelected(section);

            if (this.$route.params.subSection) {
                const subSection = this.sections
                    .find((x) => x.alias === this.$route.params.section)
                    .subSections.find((x) => x.alias === this.$route.params.subSection);

                this.subSectionSelected(subSection);

                if (this.$route.params.reportAlias) {
                    this.reportAliasSelected({ alias: this.$route.params.reportAlias });
                }
            }
        }

        this.isLoading = false;
    }

    public get filteredInstruments() {
        if (this.selectedKnowledgeModel) {
            return this.instruments.filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId || x.alias === 'generic');
        }

        return [];
    }

    public get filteredSections() {
        if (this.selectedInstrument) {
            return this.sections; //.filter((x) => x.hideFor && x.hideFor.indexOf(this.selectedInstrument.alias) === -1);
        }

        return [];
    }

    public getPendingTranslation(key: string): string {
        return this.pendingTranslatedTranslations.find((x) => x.key == key).value;
    }

    public hasPendingTranslation(key: string): boolean {
        return this.pendingTranslatedTranslations.find((x) => x.key == key) != null;
    }

    public openAddTranslationAction() {
        this.$sideActions.push(
            'add-translation-action',
            new CreateKnowledgeModelTranslationSideActionOptions({
                knowledgeModel: this.selectedKnowledgeModel,
                instrument: this.selectedInstrument && this.selectedInstrument.instrumentId > 0 ? this.selectedInstrument : null,
                languageCode: this.selectedLanguage.languageCode,
                section: this.selectedSection ? this.selectedSection.id : null,
                subSection: this.selectedSubSection ? this.selectedSubSection.id : null,
                reportAlias: this.selectedReportAlias ? this.selectedReportAlias.alias : null,
            }),
            () => {},
        );
    }

    public async reloadTranslationsKnowledgeModel(knowledgeModel: KnowledgeModel, reload: boolean = true) {
        this.selectedKnowledgeModel = knowledgeModel;

        if (this.isSuperAdmin) {
            this.allLanguages = languageService.getLanguages();
        } else {
            this.allLanguages = this.languagePermissions[0].instrumentPermissions
                .filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId)
                .map((x) => new Language(x));
        }

        const [err, response] = await to(this.knowledgeModelService.getKnowledgeModel(knowledgeModel.knowledgeModelId));

        this.languages = [];
        for (let i = 0; i < response.instruments.length; i++) {
            const instrument = response.instruments[i];
            if (instrument.languages) {
                for (let z = 0; z < instrument.languages.length; z++) {
                    if (
                        (this.isPractitioner || this.isPartner) &&
                        !this.languagePermissions[0].instrumentPermissions.find((x) => x.instrumentId === instrument.instrumentId)
                    ) {
                        continue;
                    }

                    const instrumentLang = instrument.languages[z];
                    const lang = this.allLanguages.find((x) => x.languageCode === instrumentLang);

                    if (lang && !this.languages.find((x) => x.languageCode === lang.languageCode)) {
                        this.languages.push(lang);
                    }
                }

                const inst = this.instruments.find((x) => x.instrumentId === instrument.instrumentId);
                if (inst) {
                    inst.languages = instrument.languages;
                }
            }
        }

        this.selectedInstrument = this.genericInstrument;

        if (this.selectedLanguage && !this.languages.find((x) => x.languageCode === this.selectedLanguage.languageCode)) {
            this.selectedLanguage = this.allLanguages.find((x) => x.languageCode == 'en')
                ? this.allLanguages.find((x) => x.languageCode == 'en')
                : this.allLanguages[0];
        }

        this.selectedSection = null;
        this.selectedSubSection = null;

        if (reload) {
            await this.reloadTranslations();
        }
    }

    public async reloadTranslationsInst(inst) {
        this.selectedInstrument = inst;
        this.languages = [];

        for (let z = 0; z < this.selectedInstrument.languages.length; z++) {
            if (
                (this.isPractitioner || this.isPartner) &&
                !this.languagePermissions[0].instrumentPermissions.find((x) => x.instrumentId === this.selectedInstrument.instrumentId)
            ) {
                continue;
            }

            const instrumentLang = this.selectedInstrument.languages[z];
            const lang = this.allLanguages.find((x) => x.languageCode === instrumentLang);

            if (lang && !this.languages.find((x) => x.languageCode === lang.languageCode)) {
                this.languages.push(lang);
            }
        }

        if (this.selectedLanguage && !this.languages.find((x) => x.languageCode === this.selectedLanguage.languageCode)) {
            this.selectedLanguage = this.allLanguages.find((x) => x.languageCode == 'en')
                ? this.allLanguages.find((x) => x.languageCode == 'en')
                : this.allLanguages[0];
        }

        if (inst.alias === 'generic') {
            this.selectedSubSection = this.selectedSection = null;
            this.languages = this.allLanguages;
        }

        await this.reloadTranslations();
    }

    public replaceTranslation(translationKey: string) {
        let translation = this.translations.find((x) => x.key == translationKey);
        const newTranslation = this.getPendingTranslation(translationKey);
        translation.value = newTranslation;
        this.saveContent(translation);
    }

    public async reloadTranslationsLang(lang) {
        this.selectedLanguage = lang;
        this.pendingTranslatedTranslations = [];
        await this.reloadTranslations();

        if (this.selectedSection) {
            this.sectionSelected(this.selectedSection);
        }

        if (this.selectedSubSection) {
            this.subSectionSelected(this.selectedSubSection);
        }
    }

    public hasEnglishTranslation(key): boolean {
        let translation = null;
        if (this.selectedSection) {
            translation = this.englishTranslations.filter((x) => x.section === this.selectedSection.alias).find((x) => x.key === key);
        } else {
            translation = this.englishTranslations.find((x) => x.key === key);
        }
        return !!translation;
    }

    public getEnglishTranslation(key) {
        let translation = null;
        if (this.selectedSection) {
            translation = this.englishTranslations.filter((x) => x.section === this.selectedSection.alias).find((x) => x.key === key);
        } else {
            translation = this.englishTranslations.find((x) => x.key === key);
        }
        return translation ? translation.value : 'N/A';
    }

    public getEnglishTranslationObj(key): Translation {
        let translation = null;
        if (this.selectedSection) {
            translation = this.englishTranslations.filter((x) => x.section === this.selectedSection.alias).find((x) => x.key === key);
        } else {
            translation = this.englishTranslations.find((x) => x.key === key);
        }
        return translation;
    }

    public async sectionSelected(section) {
        this.selectedSection = section;
        this.translations = this.allTranslations.filter((x) => x.section === this.selectedSection.alias);
        this.englishTranslations = this.allEnglishTranslations.filter((x) => x.section === this.selectedSection.alias);

        if (this.selectedSubSection && !this.selectedSection.subSections.find((x) => x.alias === this.selectedSubSection.alias)) {
            this.selectedSubSection = null;
        }

        this.reloaded++;
    }

    public async subSectionSelected(subSection) {
        this.selectedSubSection = subSection;
        this.translations = this.allTranslations.filter(
            (x) => x.section === this.selectedSection.alias && x.subSection === this.selectedSubSection.alias,
        );

        this.englishTranslations = this.allEnglishTranslations.filter(
            (x) => x.section === this.selectedSection.alias && x.subSection === this.selectedSubSection.alias,
        );

        this.reportAliases = [];
        this.translations.forEach((translation) => {
            if (translation.reportAlias && !this.reportAliases.find((x) => x.alias === translation.reportAlias)) {
                this.reportAliases.push({ alias: translation.reportAlias });
            }
        });

        this.reloaded++;
    }

    public reportAliasSelected(reportalias) {
        this.selectedReportAlias = reportalias;
        this.translations = this.allTranslations.filter(
            (x) =>
                x.section === this.selectedSection.alias &&
                x.subSection === this.selectedSubSection.alias &&
                x.reportAlias === this.selectedReportAlias.alias,
        );

        this.englishTranslations = this.allEnglishTranslations.filter(
            (x) =>
                x.section === this.selectedSection.alias &&
                x.subSection === this.selectedSubSection.alias &&
                x.reportAlias === this.selectedReportAlias.alias,
        );

        this.reloaded++;
    }

    public async reloadTranslations() {
        this.isLoading = true;
        this.allTranslations = this.translations = await this.getTranslations();
        await this.getEnglishTranslations();

        if (this.selectedSection) {
            this.sectionSelected(this.selectedSection);
        }

        if (this.selectedSubSection) {
            this.subSectionSelected(this.selectedSubSection);
        }

        this.reloaded++;
        this.isLoading = false;
    }

    public async getTranslations() {
        const [err, translations] = await to(
            languageService.getTranslations(
                this.selectedKnowledgeModel.alias,
                this.selectedInstrument ? this.selectedInstrument.alias : '',
                this.selectedLanguage.languageCode,
            ),
        );
        if (err) {
            this.showError('The translation are not retrieved due to a error');
            return;
        }
        return translations;
    }

    public async getEnglishTranslations() {
        const [err, translations] = await to(
            languageService.getTranslations(this.selectedKnowledgeModel.alias, this.selectedInstrument ? this.selectedInstrument.alias : '', 'en'),
        );
        if (err) {
            this.showError('The translation are not retrieved due to a error');
            return;
        }
        return (this.allEnglishTranslations = this.englishTranslations = translations);
    }

    public async saveContent(translation: Translation) {
        if (!translation.value) {
            return;
        }

        this.showPending(`Updating translation.`);

        const success = languageService.saveTranslation(
            translation,
            this.selectedKnowledgeModel.alias,
            this.selectedInstrument.alias,
            this.selectedLanguage.languageCode,
        );
        if (!success) {
            this.clearAndShowError('The content of the translation has not updated due to a error.', false);
            return;
        }

        this.clearAndShowSuccess('The content of this translation has been updated');
    }

    public deleteTranslation(translation: Translation) {
        this.showPending(`Deleting translation.`);

        const success = languageService.deleteTranslation(
            translation,
            this.selectedKnowledgeModel.alias,
            this.selectedInstrument ? this.selectedInstrument.alias : '',
            this.selectedLanguage.languageCode,
        );

        if (!success) {
            this.clearAndShowError('The the translation is not deleted due to a error.', false);
            return;
        }
        this.translations = this.translations.filter((x) => x.id !== translation.id);
        this.reloaded++;

        this.clearAndShowSuccess('The translation has been deleted');
    }

    public async translateTranslationValue(translation: Translation) {
        this.showPending(`Translating text to: ${this.selectedLanguage.languageName}.`);
        
        const translated = await languageService.translateTranslationValue(
            translation, this.selectedKnowledgeModel.alias, 
            this.selectedLanguage.languageCode);

        this.pendingTranslatedTranslations.push(new PendingTranslation({key: translation.key, value: translated}));
        this.translated ++;
        this.clearAndShowSuccess(`The value has been translated to: ${translated}`);
    }

    private async getKnowledgeModels() {
        await this.knowledgeModelService.getKnowledgeModels({}).then((response: PagedResponse<KnowledgeModel>) => {
            const knowledgeModels = response.items.map((k) => {
                return {
                    ...k,
                    knowledgeModelId: k.knowledgeModelId,
                    name: k.name,
                    alias: k.alias,
                };
            });

            if (!this.isSuperAdmin) {
                this.languagePermissions[0].instrumentPermissions.forEach((permission: InstrumentPermission) => {
                    const knowledgeModel = knowledgeModels.find((x) => x.knowledgeModelId === permission.knowledgeModelId);
                    if (knowledgeModel && !this.knowledgeModels.find((x) => x.knowledgeModelId === knowledgeModel.knowledgeModelId)) {
                        this.knowledgeModels.push(knowledgeModel);
                    }
                });
            } else {
                this.knowledgeModels = knowledgeModels;
            }

            this.selectedKnowledgeModel = this.knowledgeModels[0];
        });
    }

    private async getInstruments() {
        if (this.isSuperAdmin) {
            await this.$store.dispatch('instrumentsStore/fetch');
            const instruments = this.$store.getters['instrumentsStore/instruments'];
            return this.instruments.push(...instruments);
        }

        const instruments: Instrument[] = [];
        this.languagePermissions[0].instrumentPermissions.forEach((x) => {
            if (!instruments.find((y) => y.instrumentId === x.instrumentId)) {
                instruments.push(new Instrument(x));
            }
        });

        this.instruments.push(...instruments);
    }

    private async getLanguagePermissions() {
        this.languagePermissions = await this.languagePermissionService.getLanguagePermissions();
    }
}
