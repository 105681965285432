import BasePage from "@/models/BasePage";
import { Language } from "@/models/Language";
import { PagedResponse } from "@/models/PagedResponse";
import KnowledgeModel from "@/modules/knowledge-models/models/KnowledgeModel";
import { KnowledgeModelService } from "@/modules/knowledge-models/services/knowledgeModelService";
import { LanguagePermissionService } from "@/modules/settings/services/languagePermissionsService";
import Component from "vue-class-component";
import LanguagePermission, { InstrumentPermission } from "@/modules/settings/models/LanguagePermission";
import Glossary, { GlossaryInfo } from "../models/Glossarry";
import Instrument from '@/modules/instruments/Models/Instrument';
import { languageService } from "@/main";
import to from "await-to-js";

@Component({})
export default class Glossaries extends BasePage {
    public selectedKnowledgeModel: KnowledgeModel = null;
    public selectedLanguage: Language = new Language({ languageCode: 'nl', languageName: 'Dutch' });
    public isLoading: Boolean = true;
    public glossaries: Glossary[] = [];
    public genericInstrument: Instrument = new Instrument({ instrumentId: -1, alias: 'generic', name: 'Generic' });

    public languagePermissions: LanguagePermission[] = [];
    public knowledgeModels: KnowledgeModel[] = [];
    public languages: Language[] = [];
    public allLanguages: Language[] = [];
    public instruments: Instrument[] = [this.genericInstrument];

    public submitted: Boolean = false;
    public reloaded: number = 0;

    public languagePermissionService: LanguagePermissionService = new LanguagePermissionService();
    public knowledgeModelService: KnowledgeModelService = new KnowledgeModelService();

    public async created() {
        await this.getLanguagePermissions();
        await this.getInstruments();
        await this.getKnowledgeModels();

        if (this.isSuperAdmin) {
            this.allLanguages = languageService.getLanguages();
        } else {
            this.allLanguages = this.languagePermissions[0].instrumentPermissions
                .filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId)
                .map((x) => new Language(x));
        }

        if (this.allLanguages && this.allLanguages.length) {
            if (this.$route.params.knowledgeModelAlias) {
                this.selectedLanguage = this.allLanguages.find((x) => x.languageCode === this.$route.params.languageCode);
            } else {
                this.selectedLanguage = this.allLanguages.find((x) => x.languageCode == 'nl')
                    ? this.allLanguages.find((x) => x.languageCode == 'nl')
                    : this.allLanguages[0];
            }
        } else {
            this.showError(`You don't have rights to any language`);
        }

        await this.reloadGlossaryForKnowledgeModel(this.selectedKnowledgeModel);

        this.isLoading = false;
    }

    public isLastGlossaryEntry(glossary: number) {
        return this.glossaries.length - 1 == glossary;
    }

    public addGlossaryEntry() {
        this.glossaries.push(new Glossary({targetLanguage: this.selectedLanguage.languageCode, from: '', to: '', sourceLanguage: 'EN-US'}));
    }

    public deleteGlossaryEntry(index: number) {
        this.glossaries.splice(index, 1);
    }

    public async reloadGlossaryForKnowledgeModel(knowledgeModel: KnowledgeModel, reload: boolean = true) {
        this.selectedKnowledgeModel = knowledgeModel;

        if (this.isSuperAdmin) {
            this.allLanguages = languageService.getLanguages();
        } else {
            this.allLanguages = this.languagePermissions[0].instrumentPermissions
                .filter((x) => x.knowledgeModelId === this.selectedKnowledgeModel.knowledgeModelId)
                .map((x) => new Language(x));
        }

        const [err, response] = await to(this.knowledgeModelService.getKnowledgeModel(knowledgeModel.knowledgeModelId));

        this.languages = [];
        for (let i = 0; i < response.instruments.length; i++) {
            const instrument = response.instruments[i];
            if (instrument.languages) {
                for (let z = 0; z < instrument.languages.length; z++) {
                    if (
                        (this.isPractitioner || this.isPartner) &&
                        !this.languagePermissions[0].instrumentPermissions.find((x) => x.instrumentId === instrument.instrumentId)
                    ) {
                        continue;
                    }

                    const instrumentLang = instrument.languages[z];
                    const lang = this.allLanguages.find((x) => x.languageCode === instrumentLang);

                    if (lang && !this.languages.find((x) => x.languageCode === lang.languageCode)) {
                        this.languages.push(lang);
                    }
                }

                const inst = this.instruments.find((x) => x.instrumentId === instrument.instrumentId);
                if (inst) {
                    inst.languages = instrument.languages;
                }
            }
        }

        if (this.selectedLanguage && !this.languages.find((x) => x.languageCode === this.selectedLanguage.languageCode)) {
            this.selectedLanguage = this.allLanguages.find((x) => x.languageCode == 'nl')
                ? this.allLanguages.find((x) => x.languageCode == 'nl')
                : this.allLanguages[0];
        }
        if (reload) {
            await this.reloadGlossary();
        }
    }

    public async reloadGlossaryForLanguage(language: Language, reload: boolean = true) {
        this.selectedLanguage = language;
        if (reload) {
            await this.reloadGlossary();
        }
    }

    public async reloadGlossary() {
            this.isLoading = true;
            this.glossaries = await this.getGlossaryEntries();
            this.reloaded++;
            this.isLoading = false;
        }
    
    public async getGlossaryEntries(): Promise<Glossary[]> {
        const [err, glossaryEntries] = await to(
            languageService.GetGlossary(
                this.selectedKnowledgeModel.alias,
                this.selectedLanguage.languageCode,
            ),
        );
        if (err) {
            this.showWarning('No Glossary has been found, you will be able to create a new one');
            return [new Glossary({targetLanguage: this.selectedLanguage.languageCode, sourceLanguage: 'EN-US', to: '', from: '' })];
        }
        return glossaryEntries.length > 0 ? glossaryEntries : [new Glossary({targetLanguage: this.selectedLanguage.languageCode, sourceLanguage: 'EN-US', to: '', from: '' })];
    }

    public async saveGlossary(): Promise<boolean> {
        this.submitted = true;
        this.showPending('Saving glossary');
        await this.removeEmptyGlossaryEntries();
        const [err, glossary] = await to(
            languageService.SaveGlossary(this.selectedKnowledgeModel.alias,
                this.selectedLanguage.languageCode, this.glossaries)
        );
        this.submitted = false;
        if (err) {
            this.clearAndShowError('The glossary failed to save');
            return false;
        }

        if (glossary.name == this.selectedKnowledgeModel.alias && glossary.target_lang == this.selectedLanguage.languageCode) {
            this.clearAndShowSuccess("Glossary saved!");
            return true;
        } else {
            this.clearAndShowError('Save glossary does not correspond to the current open glossary')
            return false;
        }
    }

    private async removeEmptyGlossaryEntries() {
        this.glossaries = this.glossaries.filter(x => x.from || x.from.trim()) 
    }

    private async getKnowledgeModels() {
            await this.knowledgeModelService.getKnowledgeModels({}).then((response: PagedResponse<KnowledgeModel>) => {
                const knowledgeModels = response.items.map((k) => {
                    return {
                        ...k,
                        knowledgeModelId: k.knowledgeModelId,
                        name: k.name,
                        alias: k.alias,
                    };
                });
    
                if (!this.isSuperAdmin) {
                    this.languagePermissions[0].instrumentPermissions.forEach((permission: InstrumentPermission) => {
                        const knowledgeModel = knowledgeModels.find((x) => x.knowledgeModelId === permission.knowledgeModelId);
                        if (knowledgeModel && !this.knowledgeModels.find((x) => x.knowledgeModelId === knowledgeModel.knowledgeModelId)) {
                            this.knowledgeModels.push(knowledgeModel);
                        }
                    });
                } else {
                    this.knowledgeModels = knowledgeModels;
                }
    
                this.selectedKnowledgeModel = this.knowledgeModels[0];
            });
        }

    private async getInstruments() {
        if (this.isSuperAdmin) {
            await this.$store.dispatch('instrumentsStore/fetch');
            const instruments = this.$store.getters['instrumentsStore/instruments'];
            return this.instruments.push(...instruments);
        }

        const instruments: Instrument[] = [];
        this.languagePermissions[0].instrumentPermissions.forEach((x) => {
            if (!instruments.find((y) => y.instrumentId === x.instrumentId)) {
                instruments.push(new Instrument(x));
            }
        });

        this.instruments.push(...instruments);
    }
    
    private async getLanguagePermissions() {
        this.languagePermissions = await this.languagePermissionService.getLanguagePermissions();
    }
}